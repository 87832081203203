import { createContext, useContext } from 'react';
import {
  CreateDataTableFromTemplateStepContext,
  CreateDataTableFromTemplateStepContextProps,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';

const CreateDataTableFromTemplateContext = createContext<CreateDataTableFromTemplateStepContext | null>(null);

export const CreateDataTableFromTemplateProvider: React.FC<CreateDataTableFromTemplateStepContextProps> = ({
  children,
  value,
}) => {
  return (
    <CreateDataTableFromTemplateContext.Provider value={value}>{children}</CreateDataTableFromTemplateContext.Provider>
  );
};

export const useCreateDataTableFromTemplateContext = (): CreateDataTableFromTemplateStepContext => {
  const context = useContext(CreateDataTableFromTemplateContext);
  if (!context) {
    throw new Error('useCreateDataTableFromTemplateContext must be used within a CreateDataTableFromTemplateProvider');
  }
  return context;
};

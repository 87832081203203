import useStepForm from '@/support/Hooks/form/useStepForm';
import { FC, useReducer } from 'react';
import { useMemo } from 'react';

import { SelectAnimals } from '@/components/Studies/Templates/CreateDataTable/steps/SelectAnimals.tsx';
import { SelectTemplate } from '@/components/Studies/Templates/CreateDataTable/steps/SelectTemplate.tsx';
import {
  createFormPayload,
  initializeFormData,
  initializer,
  reducer,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.utils.ts';
import { useHistory, useParams } from 'react-router-dom';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import type { Study } from '@/model/Study.model.ts';
import { _isNil } from '@/littledash.ts';
import Loading from '@/components/Loading';
import { MapMeasurements } from '@/components/Studies/Templates/CreateDataTable/steps/MapMeasurements.tsx';
import { ConfirmColumnDates } from '@/components/Studies/Templates/CreateDataTable/steps/ConfirmColumnDates.tsx';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { errorToast, successToast } from '@/helpers.tsx';
import { DataTableCreateFromTemplateRequestV1 } from '@/model/DataTable.model.ts';
import { RouteService } from '@/support/RouteService.ts';
import {
  CreateDataTableFromTemplateFormState,
  CreateDataTableFromTemplateStep,
  StepSubmitData,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';
import InVivoError from '@/model/InVivoError.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { CreateDataTableFromTemplateProvider } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplateProvider.tsx';

const allSteps: CreateDataTableFromTemplateStep[] = [
  {
    name: 'select_template',
    title: 'Select Template',
    display: () => true,
    render: () => <SelectTemplate />,
  },
  {
    name: 'map_measurements',
    title: 'Map Measurements',
    display: (mappingRequired: boolean) => mappingRequired,
    render: () => <MapMeasurements />,
  },
  {
    name: 'confirm_dates',
    title: 'Confirm column dates',
    display: (mappingRequired: boolean, containsObservations: boolean) => mappingRequired || containsObservations,
    render: () => <ConfirmColumnDates />,
  },
  {
    name: 'select_animals',
    title: 'Select Animals',
    display: () => true,
    render: () => <SelectAnimals />,
  },
];

const CreateDataTableFromTemplate: FC = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const history = useHistory();

  const { invoke: postDatatableFromTemplate, loading: postingDatatable } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/template/{dataTableTemplateApiId}/create',
    path: { studyApiId: 'sdy_default', dataTableTemplateApiId: 'dtt_default' },
    invokeOnInit: false,
  });

  const { response: studyResponse, loading: studyLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.show.p',
    path: { id: studyId },
    query: { include: 'users' },
    options: { onError: { toast: true, slug: 'study-load' } },
  });

  const study = (studyResponse?.body as { data: Study })?.data;

  const [state, dispatch] = useReducer(reducer, {}, initializer);

  const steps = useMemo(() => {
    return allSteps.filter(
      (s) =>
        s.display(state.generic_state.mappingRequired ?? false, state.generic_state.containsObservations ?? false) ??
        false
    );
  }, [state.generic_state.mappingRequired, state.generic_state.containsObservations]);

  const { step: currentStep, nextStep, backStep, hasBack, hasNext } = useStepForm({ totalSteps: steps.length });

  const postDataTableFromTemplate = async (state: CreateDataTableFromTemplateFormState) => {
    const stepFormPayload: DataTableCreateFromTemplateRequestV1 = createFormPayload(state);
    try {
      await postDatatableFromTemplate({
        path: {
          studyApiId: study?.api_id,
          dataTableTemplateApiId: state?.select_template.template?.api_id ?? 'dtt_default',
        },
        body: stepFormPayload,
      }).then((response) => {
        history.push(
          RouteService.web({
            route: 'studies.dataTables.show',
            path: { id: studyId, dataTableApiId: response?.body?.id },
          }).route
        );
        successToast('Data table created successfully!');
      });
    } catch (err) {
      const ivError = new InVivoError('Could not create data table from template', {
        slug: 'create-data-table-from-template',
        cause: err,
      });
      ExceptionHandler.captureException(ivError);
      errorToast('Could not create data table from template');
    }
  };

  const stepSubmitHandler = (stepSubmitData: StepSubmitData) => {
    switch (stepSubmitData.step) {
      case 'select_template': {
        const { templateData } = stepSubmitData;
        if (state.select_template.template?.api_id !== templateData.template?.api_id) {
          const initializedForm = initializeFormData(templateData, study);
          dispatch({
            type: 'initialize-form',
            select_template: initializedForm.select_template,
            map_measurements: initializedForm.map_measurements,
            confirm_dates: initializedForm.confirm_dates,
            select_animals: initializedForm.select_animals,
            generic_state: initializedForm.generic_state,
          });
        } else {
          dispatch({
            type: 'template-update',
            data: templateData,
          });
        }
        nextStep();
        break;
      }
      case 'map_measurements': {
        const { mapMeasurementsData, confirmDatesData, genericData } = stepSubmitData;
        dispatch({
          type: 'map-measurements-update',
          map_measurements: mapMeasurementsData,
          confirm_dates: confirmDatesData,
          generic_state: genericData,
        });
        nextStep();
        break;
      }
      case 'confirm_dates': {
        const { confirmDatesData } = stepSubmitData;
        dispatch({
          type: 'confirm-dates-update',
          data: confirmDatesData,
        });
        nextStep();
        break;
      }
      case 'select_animals': {
        const { selectAnimalsData } = stepSubmitData;
        dispatch({
          type: 'select-animals-update',
          data: selectAnimalsData,
        });
        postDataTableFromTemplate({ ...state, select_animals: selectAnimalsData });
        break;
      }
    }
  };

  const handleBackStep = () => {
    if (hasBack) {
      backStep();
    }
  };

  if (studyLoading || _isNil(study)) {
    return <Loading txt="Loading Data table form" />;
  }

  if (postingDatatable) {
    return <Loading txt="Creating data table" />;
  }

  return (
    <div>
      <CreateDataTableFromTemplateProvider
        value={{
          onSubmit: stepSubmitHandler,
          onCancel: handleBackStep,
          submitButtonText: hasNext ? 'Next' : 'Submit',
          cancelButtonText: hasBack ? 'Back' : 'Cancel',
          study,
          state,
        }}
      >
        <div>{steps?.[currentStep]?.render() ?? null}</div>
      </CreateDataTableFromTemplateProvider>
    </div>
  );
};

export default CreateDataTableFromTemplate;

import { DataTableColumn } from '@/components/UI/DataTable/DataTable.model.ts';
import { DateTimePickerNative } from '@/components/UI/DateTimePickerNative/DateTimePickerNative.tsx';
import { Duration, normalize as normalizeDuration } from '@/components/UI/FormElements/Duration/Duration.tsx';
import Switch from '@/components/UI/FormElements/Switch';
import { _notNil } from '@/littledash.ts';
import { DataTablePkDoseColumnFillRequestV1 } from '@/model/DataTable.model.ts';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal.tsx';
import { roundToNearestHours } from 'date-fns';
import { FC } from 'react';
import { Controller, useForm, Validate } from 'react-hook-form@latest';
import { parse as parseDuration } from 'tinyduration';

type FormData = Omit<DataTablePkDoseColumnFillRequestV1, 'type'>;
const durationValidator: Validate<string, FormData> = (value) => {
  try {
    const parsedDuration = parseDuration(value);
    const nonZero = Object.values(parsedDuration).some((value) => value > 0);
    if (!nonZero) {
      return 'Duration cannot be zero';
    }
  } catch (e) {
    return 'Invalid duration';
  }
  return true;
};

interface DataTableColumnFillProps {
  column: DataTableColumn;
  onFill: (data: DataTablePkDoseColumnFillRequestV1) => Promise<void>;
  onCancel: () => void;
}

export const DataTableColumnFill: FC<DataTableColumnFillProps> = ({ column, onFill, onCancel }) => {
  const formMethods = useForm<Omit<DataTablePkDoseColumnFillRequestV1, 'type'>>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      start: roundToNearestHours(new Date()).toISOString(),
      duration: _notNil(column.pk?.interval)
        ? normalizeDuration({ minutes: Math.floor(column.pk.interval / 60) })
        : 'PT30M',
      overwrite: false,
    },
  });
  const handleSubmit = async (formData: FormData) => {
    await onFill({ type: 'pk_dose', ...formData });
  };
  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Fill column" />
      <form
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        data-test-component="DataTableColumnFill"
        data-test-element="form"
      >
        <Controller
          control={formMethods.control}
          name="start"
          render={({ field }) => (
            <div
              className="pa3 flex justify-between flex-wrap items-center align-center"
              data-test-element="start-field"
            >
              <label>Start time</label>
              <DateTimePickerNative
                style={{ marginBottom: 0 }}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            </div>
          )}
        />
        <Controller
          control={formMethods.control}
          name="duration"
          rules={{ validate: durationValidator }}
          render={({ field, fieldState }) => (
            <div
              className="pa3 bt b--moon-gray flex justify-between flex-wrap items-center align-center"
              data-test-element="duration-field"
            >
              <label>Time between doses</label>
              <Duration
                value={field.value}
                disabled={field.disabled}
                error={fieldState.invalid}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            </div>
          )}
        />
        <Controller
          control={formMethods.control}
          name="overwrite"
          render={({ field }) => (
            <div
              className="pa3 bt b--moon-gray flex justify-between flex-wrap items-center align-center"
              data-test-element="overwrite-field"
            >
              <span>
                <label>Overwrite existing values</label>
                <small>All timestamps in this column will be replaced</small>
              </span>
              <Switch value={field.value} onChange={field.onChange} onBlur={field.onBlur} />
            </div>
          )}
        />
      </form>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        submitBtnText="Fill"
        submitButtonProps={{
          disabled: !formMethods.formState.isValid && formMethods.formState.isDirty,
        }}
        onCancel={onCancel}
        cancelBtnText="Cancel"
      />
    </ModalContainer>
  );
};

import React from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form@latest';
import Header from '@/components/UI/Header';
import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { ReferenceDateColumnGroupInput } from '@/components/Studies/Templates/CreateDataTable/ReferenceDateColumnGroup.tsx';
import { DateUtils } from '@/utils/Date.utils.ts';
import { ISODate } from '@/model/Common.model.ts';
import {
  ReferenceDateColumnGroup,
  ReferenceDateFormState,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';
import { useCreateDataTableFromTemplateContext } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplateProvider.tsx';

export const ConfirmColumnDates: React.FC = () => {
  const { onSubmit, onCancel, submitButtonText, cancelButtonText, study, state } =
    useCreateDataTableFromTemplateContext();

  const formMethods = useForm({
    defaultValues: {
      studyReferenceDate: state.confirm_dates.studyReferenceDate,
      referenceDateColumnGroups: state.confirm_dates.referenceDateColumnGroups,
    },
  });

  const referenceDateOnChange = (referenceDate: ISODate) => {
    const referenceDateColumnGroups = formMethods.getValues('referenceDateColumnGroups');
    const updatedRefDateGroups = referenceDateColumnGroups.map((group) => {
      const updatedColumns = group.referenceDateColumns.map((column) => {
        return {
          ...column,
          date: DateUtils.addOffsetToISODate(referenceDate, column.referenceDateOffset ?? ''),
        };
      });
      return {
        ...group,
        referenceDateColumns: updatedColumns,
      };
    });

    formMethods.setValue(`studyReferenceDate`, referenceDate, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });

    formMethods.setValue(`referenceDateColumnGroups`, updatedRefDateGroups, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    formMethods.trigger();
  };

  const handleConfirmDatesSubmit = (data: ReferenceDateFormState) => {
    onSubmit({
      step: 'confirm_dates',
      confirmDatesData: {
        ...state.confirm_dates,
        referenceDateColumnGroups: data.referenceDateColumnGroups,
        studyReferenceDate: data.studyReferenceDate,
      },
    });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleConfirmDatesSubmit)}
          data-test-component="MapMeasurements"
          data-test-element="form"
        >
          <div className="ph4 pv3">
            <div className="pb3">
              <Header mainHeaderText="Confirm dates" />
            </div>
            <div className="">
              <Controller
                name="studyReferenceDate"
                control={formMethods.control}
                render={({ field: { value } }) => (
                  <div className="flex items-center pb3">
                    <label className="w-20">Reference Date</label>
                    <div className="w-30">
                      <DatePickerNative
                        value={value ?? ''}
                        onChange={referenceDateOnChange}
                        testId="reference-date-input"
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="pt2 pb4">
              <div className="flex bb">
                <label className="w-20 pb3 f6 dark-gray">Column</label>
                <label className="w-30 dark-gray">Date</label>
                <label className="w-20 dark-gray">Study day</label>
              </div>
              {formMethods
                .getValues('referenceDateColumnGroups')
                ?.map((referenceDateColumnGroup: ReferenceDateColumnGroup, index: number) => (
                  <ReferenceDateColumnGroupInput
                    referenceDateColumnGroup={referenceDateColumnGroup}
                    index={index}
                    studyStartDate={study.started_on}
                  />
                ))}
            </div>
            <div className="pt3 b--moon-gray">
              <Button
                submit
                testKey="createDataTableFromTemplateStepFormSubmit"
                testId="confirm-column-dates-submit"
                disabled={!formMethods.formState.isValid}
              >
                {submitButtonText}
              </Button>
              <Button plain className="ml2" onClick={onCancel} testId="select-template-cancel">
                {cancelButtonText}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

import { initAnimalSettings } from '@/components/Colony/Colony.utils';
import { Animal, AnimalV1 } from '@/model/Animal.model';
import { ClassificationType, Treatment } from '@/model/Treatment.model';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { StepFormState } from '@/components/UI/StepForm/StepForm.model';
import { ID } from '@/model/Common.model';
import { Study } from '@/model/Study.model';
import { animalMetadataColumns, studyCalculationColumns } from '@/components/Animals/AnimalsTable/AnimalsTable.utils';
import { calculateAge } from '@/helpers';
import { DateUtils } from '@/utils/Date.utils';
import { isDeadV1 } from '@/constants/utils';
import Link from '@/components/UI/Link';
import GroupLabel from '@/components/UI/GroupLabel';
import { _notNil } from '@/littledash';
import { AnimalMetadataFilter } from '@/components/Colony/Animals/Animals.utils';
import { PresetCalculation } from '@/model/PresetCalculation.model';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { FeatureFlagType } from '@/model/FeatureFlag.model.ts';

interface BulkDosage {
  animal_id: string;
  comment: string;
  dosed_at: string;
  treatment_id: string;
}

interface DiseaseInduction {
  induct: boolean;
}

export interface CreateBulkDosingState extends StepFormState<CreateBulkDosingState> {
  stepReady: boolean;
  finalStepReady: boolean;
  selectedAnimals: Animal[];
  selectedAnimals2: boolean;
  selectedTreatments: Treatment[];
  dosages: BulkDosage[];
  testBoolean: boolean;
  testNumber: number;
  testAlpha: string;
  study: Study;
  animals: any;
  treatmentClassification: ClassificationType;
  inductDisease: DiseaseInduction;
}

export const setupInitialState = async (
  studyId: ID,
  treatmentClassification: ClassificationType,
  steps: any[]
): Promise<CreateBulkDosingState> => {
  const url = apiRoute('studies.show.p', { id: studyId }, { include: 'study_groups' });
  const data = await Http.get(url);
  const study = data.data.data;

  const {
    data: { data: metadataData },
  } = await Http.get(apiRoute('meta-glossary.show'), {
    params: {
      filter_type: 'subject_meta',
    },
  });

  const initialAnimalSettings = initAnimalSettings([], true, false).settings;

  const initialState = {
    selectedAnimals: [],
    selectedAnimals2: false,
    testBoolean: false,
    testNumber: 123,
    testAlpha: 'abc',
    selectedTreatments: [],
    dosages: [],
    treatmentClassification: treatmentClassification,
    inductDisease: { induct: false },
    stepReady: false,
    finalStepReady: false,
    currentStep: 0,
    steps,
    complete: false,
    study,

    animals: {
      species: undefined,
      initialData: [],
      data: [],
      settings: initialAnimalSettings,
      cellErrors: {},
      metadata: metadataData,
      metaGlossaries: [],
      invalidCells: {},
      overlay: '',
    },
    Summary: {},
    apiError: undefined,
  };

  return initialState;
};

export const dosingAnimalTableColumns = (
  studyId: ID,
  calculations: PresetCalculation[],
  columns: Record<string, boolean>,
  changePercentage: string,
  metadataOptions: AnimalMetadataFilter[] | undefined,
  features: Partial<Record<FeatureFlagType, boolean>>
): Array<Column<AnimalV1>> => {
  const calculationColumns = studyCalculationColumns(calculations, columns, changePercentage);
  const metadataColumns = animalMetadataColumns(metadataOptions, columns);

  return [
    {
      id: 'number',
      Header: 'No.',
      width: 75,
      accessor: 'number',
      isVisible: columns['number'],
    },
    {
      id: 'catalog',
      Header: 'Animal No.',
      accessor: 'catalog',
      isVisible: columns['catalog'],
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      isVisible: columns['id'],
      Cell: ({
        row: {
          original: { id: animalId, name },
        },
      }) => (
        <div className="flex justify-between items-center w-100">
          <Link
            to={webRoute('animals.show', {
              id: studyId,
              subject_id: animalId,
            })}
            className="link blue"
          >
            {name}
          </Link>
        </div>
      ),
    },
    {
      id: 'tail',
      Header: 'Tail ID',
      accessor: 'alt_ids.tail',
      isVisible: columns['tail'],
      sortDisabled: true,
    },
    {
      id: 'donor',
      Header: 'Donor ID',
      accessor: 'alt_ids.donor',
      isVisible: columns['donor'],
      sortDisabled: true,
    },
    {
      id: 'ear',
      Header: 'Ear ID',
      accessor: 'alt_ids.ear',
      isVisible: columns['ear'],
      sortDisabled: true,
    },
    {
      id: 'tag',
      Header: 'Tag ID',
      accessor: 'alt_ids.tag',
      isVisible: columns['tag'],
      sortDisabled: true,
    },
    {
      id: 'cage',
      Header: 'Cage',
      accessor: 'cage.name',
      isVisible: columns['cage'],
      Cell: ({ row: { original } }) => isDeadV1(original) || '—',
    },
    ...calculationColumns,
    {
      id: 'study_group',
      Header: 'Study group',
      accessor: 'study_group.no',
      isVisible: columns['study_group'],
      width: 200,
      Cell: ({
        row: {
          original: { study_group: studyGroup },
        },
      }) => (studyGroup ? <GroupLabel group={studyGroup} /> : '—'),
    },
    {
      id: 'tracking_started_at',
      Header: 'Tracking date',
      accessor: 'tracking_started_at',
      isVisible: columns['tracking_started_at'],
      Cell: ({
        row: {
          original: { tracking_started_at: trackingStartedAt },
        },
      }) => <>{DateUtils.renderDate(trackingStartedAt, { defaultResponse: '' })}</>,
    },
    ...((features?.data_analysis ?? false)
      ? [
          {
            id: 'disease_inducted_at',
            Header: 'Disease Induction Date',
            accessor: 'disease_inducted_at',
            isVisible: columns['disease_inducted_at'],
            Cell: ({
              row: { original },
            }: {
              row: {
                original: {
                  disease_inducted_at: string | undefined;
                };
              };
            }) => <>{DateUtils.renderDate(original.disease_inducted_at, { defaultResponse: '' })}</>,
          },
        ]
      : []),
    {
      id: 'sex',
      Header: 'Sex',
      accessor: 'sex',
      sortBy: 'sex',
      isVisible: columns['sex'],
      width: 75,
      Cell: ({
        row: {
          original: { sex },
        },
      }) => sex?.toUpperCase?.() ?? '—',
    },
    {
      id: 'dob',
      Header: 'DOB',
      accessor: 'dob',
      isVisible: columns['dob'],
      Cell: ({
        row: {
          original: { dob },
        },
      }) => <>{DateUtils.renderDate(dob, { defaultResponse: '-' })}</>,
    },
    {
      id: 'age',
      Header: 'Age',
      accessor: 'ageValue',
      isVisible: columns['age'],
      sortDisabled: true,
      Cell: ({
        row: {
          original: { terminated_at: terminatedAt, dob },
        },
      }) => (_notNil(dob) ? calculateAge(dob, terminatedAt) : '—'),
    },
    {
      id: 'species',
      Header: 'Species',
      accessor: 'species_name',
      isVisible: columns.species ?? false,
      sortDisabled: true,
    },
    {
      id: 'strain',
      Header: 'Strain',
      accessor: 'strain_name',
      isVisible: columns.strain ?? false,
      sortDisabled: true,
    },
    ...metadataColumns,
  ];
};

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form@latest';
import Select from '@/components/UI/Select';
import { SingleValue } from 'react-select';
import {
  TemplateInputMeasurement,
  TemplateOutputMeasurement,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';

export interface MeasurementSelectGroupProps {
  templateMeasurement: TemplateOutputMeasurement;
  index: number;
  outputMeasurementOptions: { value: string; label: string }[];
  inputMeasurementOptions: Record<string, { value: string; label: string }[]>;
  availableOutputOptions: { value: string; label: string }[];
  availableInputOptions: Record<string, { value: string; label: string }[]>;
  handleOutputChange: (index: number, value: string | null) => void;
  handleInputChange: (parentIndex: number, childIndex: number, value: string | null) => void;
}

export const MeasurementSelectGroup: React.FC<MeasurementSelectGroupProps> = ({
  templateMeasurement,
  index,
  outputMeasurementOptions,
  inputMeasurementOptions,
  availableOutputOptions,
  availableInputOptions,
  handleOutputChange,
  handleInputChange,
}) => {
  const { control } = useFormContext();

  const renderInputMeasurement = (inputMeasurement: TemplateInputMeasurement, innerIndex: number) => (
    <Controller
      key={inputMeasurement.slug}
      name={`templateMeasurements.${index}.inputMeasurements.${innerIndex}.presetSlug`}
      control={control}
      rules={{
        required: 'Mapping required',
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="flex items-center mt2">
          <label className="w-20 pl4">{inputMeasurement.columnName}</label>
          <div
            className="flex w-80"
            data-testid={`templateMeasurements.${index}.inputMeasurements.${innerIndex}.presetSlug`}
          >
            <Select
              name={`templateMeasurements.${index}.inputMeasurements.${innerIndex}.presetSlug`}
              className="w-30 pr2"
              value={
                inputMeasurementOptions?.[templateMeasurement.presetSlug as string]?.find(
                  (option) => option.value === value
                ) ?? ''
              }
              isMulti={false}
              isSearchable={false}
              isClearable={true}
              options={availableInputOptions?.[templateMeasurement.presetSlug as string] ?? []}
              onChange={(option: SingleValue<{ value: string; label: string }>) => {
                handleInputChange(index, innerIndex, option?.value ?? null);
              }}
            />
            {error && (
              <p
                className="red mt2 f6"
                data-testid={`templateMeasurements.${index}.inputMeasurements.${innerIndex}.presetSlug-error`}
              >
                {error.message}
              </p>
            )}
          </div>
        </div>
      )}
    />
  );

  return (
    <div className="pb3 pt3 bt">
      <Controller
        key={templateMeasurement.slug}
        name={`templateMeasurements.${index}.presetSlug`}
        control={control}
        rules={{
          required: 'Mapping required',
        }}
        render={({ field: { value }, fieldState: { error } }) => (
          <div className="flex items-center">
            <label className="w-20 mb0">{templateMeasurement.columnName}</label>
            <div className="flex w-80" data-testid={`templateMeasurements.${index}.presetSlug`}>
              <Select
                name={`templateMeasurements.${index}.presetSlug`}
                className="w-40"
                value={outputMeasurementOptions.find((option) => option.value === value) ?? ''}
                isMulti={false}
                isSearchable={false}
                isClearable={true}
                options={availableOutputOptions}
                onChange={(option) => handleOutputChange(index, option?.value ?? null)}
              />
              {error && (
                <p className="red mt2 f6" data-testid={`templateMeasurements.${index}.presetSlug-error`}>
                  {error.message}
                </p>
              )}
            </div>
          </div>
        )}
      />

      <div className="pt1">{templateMeasurement.inputMeasurements?.map(renderInputMeasurement)}</div>
    </div>
  );
};

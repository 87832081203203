import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import RenderWorkflowDosing from '@/components/Subjects/Show/Dosing/RenderWorkflowDosing';
import Deceased from '@/components/Subjects/Show/Notifications/Types/Deceased';
import Banner from '@/components/UI/Banner';
import { checkIsDeceased } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import type { Dose } from '@/model/Dose.model';
import type { LatestMeasurement } from '@/model/LatestMeasurements.model';
import type { Study } from '@/model/Study.model';
import type { Treatment, TreatmentApiId } from '@/model/Treatment.model';
import { useFetchEntity } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { FC, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import DoseList from './DoseList';

interface DosingViewProps {
  study: Study;
  setSubject: (animal: unknown) => void;
  subject: Animal;
  dosages: Array<Dose>;
  fetchAnimalDataByType: (
    subject: Animal,
    type: 'measurements' | 'samples' | 'observations' | 'dosing',
    query?: Record<string, string | number | boolean | Array<string | number | boolean>>
  ) => void;
  openModal: () => void;
  closeModal: () => void;
  workflow: any;
  onSave: () => void;
  canWrite: boolean;
  dosed_at: string;
  openSetupWorkflow: () => void;
  hasToggleDosing: boolean;
  studyTreatments: Array<Treatment>;
  isClicked?: boolean;
}

type AnimalAPIResponse = { data: { data: Animal } };

const DosingView: FC<DosingViewProps> = ({
  study,
  setSubject,
  subject,
  dosages,
  fetchAnimalDataByType,
  closeModal,
  workflow,
  onSave,
  canWrite,
  dosed_at,
  openSetupWorkflow,
  hasToggleDosing,
  isClicked = true,
  studyTreatments,
}) => {
  const [animalError, setAnimalError] = useState<boolean | unknown>(false);
  const {
    entity: bodyweight,
    entityLoading: bodyweightLoading,
    entityError: bodyweightError,
  } = useFetchEntity<LatestMeasurement>({
    entityType: 'animalLatestMeasurement',
    params: { animalId: subject?.id ?? '', studyId: study?.id ?? '', measurement: 'weight' },
  });

  const treatments: Array<Treatment> = (workflow?.children ?? []).reduce(
    (acc: Array<Treatment>, treatmentApiId: TreatmentApiId) => {
      const treatment = studyTreatments.find((treatment) => treatment.api_id === treatmentApiId);
      if (_notNil(treatment)) {
        acc.push(treatment);
      }
      return acc;
    },
    []
  );

  const treatmentStudyGroups = useMemo(
    () =>
      treatments?.reduce((acc: Set<ID>, treatment: Treatment) => {
        if (_isNotEmpty(treatment.study_groups)) {
          treatment['study_groups'].forEach(({ id }) => acc.add(id));
        }
        return acc;
      }, new Set()),
    [treatments]
  );

  useEffect(() => {
    if (hasToggleDosing && !isClicked && _isNotEmpty(treatments)) {
      const studyGroup = subject.study_group_id;
      if (_isNil(studyGroup) || !treatmentStudyGroups.has(studyGroup)) {
        toast.success(`Dosing skipped for ${subject.name}`);
        return onSave();
      }
    }
  }, [hasToggleDosing]);

  const handleSave = () => {
    fetchAnimalDataByType(subject, 'dosing', { sort: 'created_at', order: 'desc' });
    closeModal();
  };

  const handleOnAllDosesSaved = () => {
    handleSave();
    onSave();
  };

  const handleDeceasedCallback = () => {
    closeModal();
    fetchAnimal();
  };

  const fetchAnimal = async () => {
    try {
      const results = await Http.get(
        apiRoute(
          'studies.animal.show',
          {
            id: subject.id,
            studyId: study.id,
          },
          { include: 'terminated_at_data' }
        )
      );
      const result: AnimalAPIResponse = results?.data?.data ?? {};
      setSubject(result);
      return result;
    } catch (error) {
      setAnimalError(error);
    }
  };

  const isDeceased = checkIsDeceased(subject);
  const apiError = bodyweightError || animalError;

  if (bodyweightLoading) {
    return <Loading txt="Fetching animal's bodyweight" />;
  }

  return (
    <div className="flex flex-wrap justify-between items-stetch h-100" data-testid="workflow-dosing-pane">
      <div className="w-50 br b--moon-gray bg-white">
        <>
          {apiError ? <ApiErrorBanner error={bodyweightError} /> : null}
          {isDeceased ? (
            <Banner info className="mw6 mt3" dismiss={false}>
              <Deceased subject={subject} study={study} handleCallback={handleDeceasedCallback} />
            </Banner>
          ) : (
            canWrite && (
              <RenderWorkflowDosing
                studyId={study?.id}
                animal={subject}
                dosedAt={dosed_at}
                onEachDoseSave={handleSave}
                onAllDosesSaved={handleOnAllDosesSaved}
                bodyweight={bodyweight?.value}
                treatments={treatments}
                openSetupWorkflow={openSetupWorkflow}
                takeBodyWeight={workflow.takeWeight}
              />
            )
          )}
        </>
      </div>
      <div className="w-50 bg-white" data-testid="workflow-dosage-list">
        <DoseList subject={subject} study={study} dosages={dosages} handleCallback={handleSave} />
      </div>
    </div>
  );
};

export default DosingView;

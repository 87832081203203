import { FormProvider, useForm } from 'react-hook-form@latest';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import React, { useState } from 'react';
import { MeasurementSelectGroup } from '@/components/Studies/Templates/CreateDataTable/MeasurementSelectGroup.tsx';
import {
  MapMeasurementsFormState,
  MappedColumn,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';
import { useCreateDataTableFromTemplateContext } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplateProvider.tsx';

export const MapMeasurements: React.FC = () => {
  const { onSubmit, onCancel, submitButtonText, cancelButtonText, state } = useCreateDataTableFromTemplateContext();

  const formMethods = useForm({
    defaultValues: {
      templateMeasurements: state.map_measurements.templateMeasurements,
    },
  });

  const templateMeasurements = formMethods.getValues('templateMeasurements');
  const { inputMeasurementOptions = {}, outputMeasurementOptions = [] } =
    state.map_measurements.studyMeasurementOptions || {};

  const determineOutputOptions = () => {
    const selectedOutputValues = formMethods
      .getValues('templateMeasurements')
      .map((tm) => tm.presetSlug)
      .filter(Boolean);
    return outputMeasurementOptions.filter((option) => !selectedOutputValues.includes(option.value));
  };

  const determineInputOptions = () => {
    const selectedInputValues = formMethods
      .getValues('templateMeasurements')
      .flatMap((tm) => tm.inputMeasurements || [])
      .map((input) => input.presetSlug)
      .filter(Boolean);

    return Object.fromEntries(
      Object.entries(inputMeasurementOptions).map(([key, options]) => [
        key,
        options.filter((option) => !selectedInputValues.includes(option.value)),
      ])
    );
  };

  const [availableOutputOptions, setAvailableOutputOptions] = useState(determineOutputOptions);
  const [availableInputOptions, setAvailableInputOptions] = useState(determineInputOptions);

  const handleMapMeasurementsSubmit = (data: MapMeasurementsFormState) => {
    const { templateMeasurements = [] } = data;

    const transformedReferenceDateColumnGroups = state.confirm_dates.referenceDateColumnGroups.map((group) => ({
      ...group,
    }));

    for (const outputMeasurement of templateMeasurements) {
      const group = transformedReferenceDateColumnGroups.find(
        (group) => group.columnName === outputMeasurement.columnName
      );

      // Prepare reference date inputs
      if (group) {
        // Update mappedColumnName
        group.mappedColumnName =
          outputMeasurementOptions.find((option) => option.value === outputMeasurement.presetSlug)?.label || undefined;
      }
    }

    const mappedColumns = templateMeasurements?.flatMap(({ slug, presetSlug, inputMeasurements }) => {
      // Combine the top-level slugs with those from inputMeasurements
      return [
        { templateSlug: slug, presetSlug }, // Include the top-level values
        ...inputMeasurements.map(({ slug, presetSlug }) => ({ templateSlug: slug, presetSlug })), // Add nested values
      ];
    });

    // Convert the flat array into an object where keys are templateSlugs and values are presetSlugs
    const mappedColumnsObject: MappedColumn = (mappedColumns ?? [])?.reduce(
      (acc: Record<string, string>, { templateSlug, presetSlug }) => {
        if (templateSlug) {
          acc[templateSlug] = presetSlug ?? '';
        }

        return acc;
      },
      {} as Record<string, string>
    );

    onSubmit({
      step: 'map_measurements',
      mapMeasurementsData: { ...state.map_measurements, templateMeasurements },
      confirmDatesData: { ...state.confirm_dates, referenceDateColumnGroups: transformedReferenceDateColumnGroups },
      genericData: { ...state.generic_state, mappedColumns: mappedColumnsObject },
    });
  };

  const handleOutputChange = (index: number, newValue: string | null) => {
    templateMeasurements[index]?.inputMeasurements.forEach((_, inputIndex) => {
      formMethods.setValue(
        `templateMeasurements.${index}.inputMeasurements.${inputIndex}.presetSlug`,
        '', // Reset presetSlug
        {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: false,
        }
      );
    });

    formMethods.setValue(`templateMeasurements.${index}.presetSlug`, newValue ?? '', {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });

    setAvailableOutputOptions(determineOutputOptions);
    setAvailableInputOptions(determineInputOptions);

    formMethods.trigger(`templateMeasurements.${index}`);
  };

  const handleInputChange = (outputIndex: number, inputIndex: number, newValue: string | null) => {
    formMethods.setValue(
      `templateMeasurements.${outputIndex}.inputMeasurements.${inputIndex}.presetSlug`,
      newValue as string | undefined,
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );

    setAvailableInputOptions(determineInputOptions);

    formMethods.trigger(`templateMeasurements.${outputIndex}.inputMeasurements.${inputIndex}`);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleMapMeasurementsSubmit)}
          data-test-component="MapMeasurements"
          data-test-element="form"
        >
          <div className="ph4 pv3">
            <div className="pb5">
              <Header mainHeaderText="Map measurements" />
            </div>
            <div className="flex">
              <label className="w-20 pb3 f6 dark-gray">Template measurements</label>
              <label className="w-80 dark-gray">Study measurements</label>
            </div>
            <div className="pt2 pb4">
              {templateMeasurements?.map((templateMeasurement, index) => (
                <MeasurementSelectGroup
                  templateMeasurement={templateMeasurement}
                  index={index}
                  outputMeasurementOptions={outputMeasurementOptions}
                  inputMeasurementOptions={inputMeasurementOptions}
                  availableOutputOptions={availableOutputOptions}
                  availableInputOptions={availableInputOptions}
                  handleOutputChange={handleOutputChange}
                  handleInputChange={handleInputChange}
                />
              ))}
            </div>
            <div className="pt3 b--moon-gray">
              <Button
                submit
                testKey="createDataTableFromTemplateStepFormSubmit"
                testId="map-measurements-submit"
                disabled={!formMethods.formState.isValid}
              >
                {submitButtonText}
              </Button>
              <Button plain className="ml2" onClick={onCancel} testId="select-template-cancel">
                {cancelButtonText}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

import { Controller } from 'react-hook-form@latest';
import React from 'react';
import { useFormContext } from 'react-hook-form@latest';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { differenceInDays, format } from 'date-fns';
import {
  ReferenceDateColumn,
  ReferenceDateColumnGroup,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';
import { _notNil } from '@/littledash.ts';

export interface ReferenceDateColumnGroupProps {
  referenceDateColumnGroup: ReferenceDateColumnGroup;
  index: number;
  studyStartDate: string;
}

const ColumnTypeLabels: Record<string, string> = {
  measurement_output: 'Measurement',
  score: 'Observation score',
  comments: 'Observation comments',
};

export const ReferenceDateColumnGroupInput: React.FC<ReferenceDateColumnGroupProps> = ({
  referenceDateColumnGroup,
  index,
  studyStartDate,
}) => {
  const { control } = useFormContext();

  return (
    <div className="pt3 bb">
      {referenceDateColumnGroup.referenceDateColumns.map(
        (referenceDateColumn: ReferenceDateColumn, innerIndex: number) => (
          <Controller
            key={referenceDateColumn.columnApiId}
            name={`referenceDateColumnGroups.${index}.referenceDateColumns.${innerIndex}.date`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={`flex items-center pt1 pb1`}>
                <div className="w-20">
                  <label>
                    {_notNil(referenceDateColumnGroup.mappedColumnName)
                      ? referenceDateColumnGroup.mappedColumnName
                      : referenceDateColumnGroup.columnName}
                  </label>
                  <p className="f6">{ColumnTypeLabels[referenceDateColumnGroup.type]}</p>
                </div>
                <div className="w-30">
                  <DatePickerNative
                    value={value ?? ''}
                    onChange={onChange}
                    testId={`referenceDateColumnGroups.${index}.referenceDateColumns.${innerIndex}.date`}
                  />
                </div>
                <div className="w-20">
                  <label
                    data-testid={`referenceDateColumnGroups.${index}.referenceDateColumns.${innerIndex}.date-day-label`}
                  >
                    Day {differenceInDays(referenceDateColumn.date, studyStartDate) + 1}
                  </label>
                  <p className="f6">{format(referenceDateColumn.date, 'EEEE')}</p>
                </div>
              </div>
            )}
          />
        )
      )}
    </div>
  );
};

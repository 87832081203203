import { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import SpreadSheet from '@/components/UI/SpreadSheet'; // @ts-expect-error HOT table
import type HotTable from '@handsontable/react';
import { ApiService } from '@/support/ApiService';
import { CreateBulkDosingState } from '../CreateDosing.utils';
import { CellChange } from 'handsontable/common';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';
import Banner from '@/components/UI/Banner';
import Handsontable from 'handsontable';
import { errorToast, formatNumber } from '@/helpers.tsx';
import Loading from '@/components/Loading';
import { NoDataMessage } from '@/components/NoDataCard';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import InVivoError from '@/model/InVivoError.ts';
import { AnimalApiId } from '@/model/Animal.model.ts';
import Checkbox from '@/components/UI/FormElements/Checkbox';

interface SummaryStepProps {
  state: CreateBulkDosingState;
  dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>;
}

type ExcludedDosages = {
  weight_filtered: number;
  deceased_filtered: number;
};

export const Summary: FC<SummaryStepProps> = ({ state, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const {
    study,
    animals: { metadata },
  } = state;
  const [excludedDoses, setExcludedDoses] = useState<ExcludedDosages>({ weight_filtered: 0, deceased_filtered: 0 });
  const [emptyInductedDateCount, setEmptyInductedDateCount] = useState<number>(0);
  const [addDiseaseInductionDate, setDiseaseInduction] = useState<boolean>(true);

  const classification = state?.treatmentClassification ?? 'treatment';

  const displayValueRenderer = (
    instance: Handsontable.Core,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: {
      display_value: string | null | undefined;
      display_unit: string;
    },
    cellProperties: Handsontable.CellProperties
  ) => {
    Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties);
    if (value.display_value === null || value.display_value === undefined) {
      td.innerHTML = '-';
    } else {
      td.innerHTML = `${formatNumber(value.display_value, true, 3)} ${value.display_unit}`;
    }
  };

  const commentValidator = (value: string, callback: (isValid: boolean) => void): void => {
    callback(_isEmpty(value) || value.length < 256);
  };

  const updatedColumns = {
    colHeaders: [
      'Cage name',
      'Animal name',
      'Group name',
      'Treatment name',
      'Dose',
      'Dose volume',
      'Stock',
      'Volume',
      'Time',
      'Comment',
    ],
    columns: [
      { data: 'cage_name', type: 'text', value: 'cage_name', readOnly: true },
      { data: 'animal_name', type: 'text', value: 'animal_name', readOnly: true },
      { data: 'group_name', type: 'text', value: 'group_name', readOnly: true },
      { data: 'treatment_name', type: 'text', value: 'treatment_name', readOnly: true },
      { data: 'dose', type: 'text', value: 'dose', readOnly: true, renderer: displayValueRenderer, width: '100px' },
      { data: 'dose_volume', type: 'text', value: 'dose_volume', readOnly: true, renderer: displayValueRenderer },
      { data: 'stock', type: 'text', value: 'stock', readOnly: true, renderer: displayValueRenderer, width: '100px' },
      { data: 'volume', type: 'text', value: 'volume', readOnly: true, renderer: displayValueRenderer, width: '100px' },
      { data: 'dosed_at', type: 'datetime', value: 'Time', width: '200px' },
      {
        data: 'comment',
        type: 'text',
        value: 'Comment',
        validator: commentValidator,
        validationErrorMessage: () => 'Exceeds character limit, please shorten to proceed',
      },
    ],
    columnSorting: false,
    className: 'htMiddle',
    minSpareRows: 1,
    manualColumnResize: true,
    rowHeights: 50,
    height: 500,
    autoColumnSize: true,
    stretchH: 'all' as 'none' | 'all' | 'last' | undefined,
  };

  const ref = useRef<HotTable>();
  useEffect(() => {
    if ((state?.dosages?.length ?? 0) > 0 && _notNil(ref?.current?.hotInstance)) {
      new Promise<boolean>((resolve) => ref.current.hotInstance.validateCells(resolve)).then((valid) => {
        if (state?.finalStepReady !== valid) {
          dispatch({ type: 'finalStepReady', data: valid });
        }
      });
    }
  }, [state?.dosages, state?.finalStepReady]);

  useEffect(() => {
    const animalIds: Array<AnimalApiId> = state.selectedAnimals?.reduce((acc: Array<AnimalApiId>, animal) => {
      if (_notNil(animal?.api_id)) {
        acc.push(animal.api_id);
      }
      return acc;
    }, []);
    const treatmentIds = state.selectedTreatments.map(({ api_id }) => api_id);
    ApiService.call({
      endpoint: 'POST /api/v1/studies/{studyId}/dosages/calculate',
      path: { studyId: study!.api_id },
      body: { animals: animalIds, treatments: treatmentIds },
    })
      .then((result) => {
        const dosages = result.body?.dosages.map((dosage: any) => {
          dosage.dosed_at = new Date().toISOString();
          return dosage;
        });
        setExcludedDoses({
          deceased_filtered: result.body?.deceased_filtered_out_count ?? 0,
          weight_filtered: result.body?.weight_filtered_out_count ?? 0,
        });
        setEmptyInductedDateCount(result.body?.disease_inducted_empty_count ?? 0);
        // Refactor later
        dispatch({
          type: 'updateArray',
          id: 'dosages',
          data: dosages,
        });
        setLoading(false);
      })
      .catch((error) => {
        errorToast('Failed to calculate dosages');
        ExceptionHandler.captureException(
          new InVivoError('Could not calculate dosages', {
            cause: error,
            slug: 'calculate-bulk-dosages',
          })
        );
      });
  }, []);

  const updateData = (changes: CellChange[] | null) => {
    if (_notNil(changes)) {
      const updatedSelectedDosages = [...state.dosages];
      changes.forEach((change) => {
        const [index, field, _previous, current] = change;
        updatedSelectedDosages[index] = { ...updatedSelectedDosages[index], [field]: current };
      });

      dispatch({
        type: 'updateArray',
        id: 'dosages',
        data: updatedSelectedDosages,
      });
    }
  };

  if (loading) {
    return (
      <div className="pt4">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt4" data-testid="calculate-dosages-container">
      <div className="flex flex-row">
        {!(excludedDoses.weight_filtered === 0 && excludedDoses.deceased_filtered === 0) && (
          <div className="flex flex-column">
            <Banner info className="mw6 mr4 mb4" testId={'banner-dosage-exclusion'}>
              <h3 className="f6 lh-copy fw5 pb3">One or more dosages have been excluded for the following reasons:</h3>
              <ul>
                {excludedDoses.weight_filtered !== 0 && (
                  <li className="o-list ml3 f6 lh-copy near-black">
                    {excludedDoses.weight_filtered} dosage(s) excluded because animals did not have recorded
                    measurements
                  </li>
                )}
                {excludedDoses.deceased_filtered !== 0 && (
                  <li className="o-list ml3 f6 lh-copy near-black">
                    {excludedDoses.deceased_filtered} dosage(s) excluded because animals were marked as deceased
                  </li>
                )}
              </ul>
            </Banner>
          </div>
        )}
        {emptyInductedDateCount !== 0 && classification == 'disease_induction' && (
          <div className="flex flex-column">
            <Banner info className="mw6 mr4 mb4" testId={'banner-dosage-induction'}>
              <h3>
                <span className="f6 lh-copy fw5 pb3">
                  {emptyInductedDateCount} animals do not have a disease induction date.
                </span>
                <div className="f6 pt2 lh-copy near-black">
                  This will be added automatically unless the checkbox below is disabled.
                </div>
              </h3>
            </Banner>
          </div>
        )}
      </div>
      {_isEmpty(state.dosages) && <NoDataMessage title="No doses have been recorded" />}
      <div className="ow-spreadsheet-styles">
        {_isNotEmpty(state.dosages) && (
          <SpreadSheet
            className={'mt3'}
            data={state.dosages}
            settings={updatedColumns}
            metadata={metadata}
            addCol={false}
            innerRef={ref}
            maxRows={state.dosages.length}
            afterChange={updateData}
          />
        )}
      </div>
      {emptyInductedDateCount !== 0 && classification == 'disease_induction' && (
        <Checkbox
          label="Set Disease Induction Date"
          sublabel="This date will match the dosing date above for animals with this field undefined."
          id="disease-induction"
          name="disease-induction"
          data-testid="disease-induction-checkbox"
          checked={addDiseaseInductionDate}
          onChange={() => {
            setDiseaseInduction(!addDiseaseInductionDate);
            dispatch({
              type: 'update',
              id: 'inductDisease',
              data: { induct: !addDiseaseInductionDate },
            });
          }}
          className={`mv2`}
        />
      )}
    </div>
  );
};
